<template>
    <div v-if="is_open">
        <div class="exit-intent">
            <template v-if="is_sent">
                <div class="exit-intent__success">
                    <div class="exit-intent__success__text">
                        <h2>{{ $t('components.templates.exit_intent.request_received') }}</h2>
                        <p>{{ $t('components.templates.exit_intent.we_will_send') }}</p>
                        <button @click.prevent="closeExitPopup()" class="app-btn btn-black btn-block">{{ $t('components.templates.exit_intent.close') }}</button>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="exit-intent__popup">
                    <div class="exit-intent__form">
                        <h2>{{ $t('components.templates.exit_intent.title') }}</h2>
                        <p class="exit-intent__form__description">{{ $t('components.templates.exit_intent.description') }}</p>
                        <div class="input-wrap reduce-gap">
                            <input v-model="full_name" type="text" oninput="this.setAttribute('value', this.value);" value="">
                            <label for="">{{ $t('components.templates.exit_intent.full_name') }}</label>
                        </div>
                        <div class="input-wrap reduce-gap">
                            <input v-model="email" type="email" oninput="this.setAttribute('value', this.value);" value="">
                            <label for="">{{ $t('components.templates.exit_intent.email_address') }}</label>
                        </div>
                        <div class="input-wrap">
                            <input v-model="phone_number" type="text" oninput="this.setAttribute('value', this.value);" value="">
                            <label for="">{{ $t('components.templates.exit_intent.phone_number') }}</label>
                        </div>
                        <button :disabled="dataNotFilled" @click.prevent="addLead()" class="app-btn btn-black btn-block">{{ $t('components.templates.exit_intent.get_offers') }}</button>
                        <p @click.prevent="closeExitPopup()" class="exit-intent__form__no-thanks">{{ $t('components.templates.exit_intent.no_thanks') }}</p>
                    </div>
                    <div class="exit-intent__image">
                        <img @click.prevent="closeExitPopup()" class="exit-intent__close" src="/img/templates/components/close.svg" alt="">
                        <img src="/img/templates/components/exit-intent/cover.png" alt="">
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        mounted() {
        },
        data() {
            return {
                is_open: false,
                is_sent: false,
                full_name: "",
                email: "",
                phone_number: "",
            }
        },
        methods: {
            closeExitPopup() {
                this.is_open = false;
            },
            addLead() {
                let url = '/add-lead';
                const path = window.location.pathname;

                if (path !== '/') {
                    url = `${path}${url}`;
                }

                axios.post(url, {
                    email: this.email,
                    full_name: this.full_name,
                    phone_number: this.phone_number,
                }).then(function(response) {
                    this.is_sent = true;
                }.bind(this));
            }
        },
        mounted() {
          
        },
        computed: {
            dataNotFilled() {
                if (!this.emailValid) {
                    return true;
                }

                if (!this.phone_number) {
                    return true;
                }

                if (!this.full_name) {
                    return true;
                }
            },
            emailValid() {
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(this.email).toLowerCase());
            },
        }
    }
</script>

<style lang="scss">
    .exit-intent {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 1000;
        display: flex;

        a, p, span, h2, h3 {
            font-family: 'Poppins', sans-serif;
        }

        .exit-intent__success {
            padding-left: 16px;
            padding-right: 16px;
            max-width: 100%;
            display: flex;
            flex-direction: column;
            position: relative;
            margin-left: auto;
            margin-right: auto;
            align-self: center;

            .exit-intent__success__text {
                max-width: 522px;
                background: white;
                padding: 32px 32px;
                text-align: center;

                @include media('>=tablet') {
                    padding: 32px 100px;
                }

                h2 {
                    margin-bottom: 12px;
                }

                p {
                    margin-bottom: 32px;
                    font-size: 14px;
                    color: #2C2A34;
                }
            }
        }

        .exit-intent__popup {
            padding-left: 16px;
            padding-right: 16px;
            width: 818px;
            max-width: 100%;
            display: flex;
            flex-direction: column-reverse;
            position: relative;
            margin-left: auto;
            margin-right: auto;
            align-self: center;

            @include media('>=tablet') {
                display: flex;
                flex-direction: row;
            }

            .exit-intent__image {
                height: 82px;
                position: relative;
                background: #fff;
                width: 100%;

                @include media('>=tablet') {
                    height: auto;
                    max-width: 348px;
                }

                img {
                    width: 100%;
                    display: block;
                    object-fit: cover;
                    height: 100%;
                }

                .exit-intent__close {
                    position: absolute;
                    right: 16px;
                    top: 16px;
                    width: 32px;
                    height: 32px;
                    cursor: pointer;
                }
            }

            .exit-intent__form {
                background: white;
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 24px;
                padding-bottom: 24px;

                @include media('>=tablet') {
                    padding-left: 40px;
                    padding-right: 92px;
                    padding-top: 32px;
                    padding-bottom: 28px;
                }

                h2 {
                    font-size: 36px;
                    margin-bottom: 10px;
                }

                .exit-intent__form__description {
                   color: #2C2A34;
                   font-size: 16px;
                   margin-bottom: 24px;
                   line-height: 24px;
                }

                .exit-intent__form__no-thanks {
                    cursor: pointer;
                    font-size: 16px;
                    color: #798194;
                    margin-top: 16px;
                    text-align: center;
                }
            }
        }
    }
</style>