<template>
    <div class="register-popup-container">
        <div class="register-popup">
            <div class="register-popup-contents">
                <template v-if="view == 'register_method'">
                    <div class="register-popup-contents__header">
                        <h5 class="text-primary mb-40">{{ $t('getting_started.sign_up_to_view_your_website') }}</h5>

                        <a class="button-close" @click.prevent="close()">
                            <img src="/img/close-grey.svg" alt="close" class="close-icon-s">
                        </a>
                    </div>
                    <div class="register-popup-contents__bulletpoints">
                        <div class="register-popup-contents__bulletpoint">
                            <img src="/img/check-primary.svg" alt="">
                            <p>{{ $t('getting_started.14_day_free_trial') }}</p>
                        </div>
                        <div class="register-popup-contents__bulletpoint">
                            <img src="/img/check-primary.svg" alt="">
                            <p>{{ $t('getting_started.no_card_required') }}</p>
                        </div>
                        <div class="register-popup-contents__bulletpoint">
                            <img src="/img/check-primary.svg" alt="">
                            <p>{{ $t('getting_started.cancel_anytime') }}</p>
                        </div>
                    </div>

                    <a class="button button-l button-block button-social" :href="getGoogleRedirectUrl">
                        <img src="/img/logos/google.svg" alt=""><span>{{ $t('auth.login.continue_with_google') }}</span>
                    </a>

                    <a class="button button-l button-block button-social" :href="getFacebookRedirectUrl">
                        <img src="/img/logos/fb.svg" alt=""><span>{{ $t('auth.login.continue_with_facebook') }}</span>
                    </a>

                    <a class="button button-l button-block button-social" @click.stop="view='form'">
                        <img src="/img/logos/mail.svg" alt=""><span>{{ $t('auth.login.continue_with_email') }}</span>
                    </a>

                    <div class="register-popup-contents__rules-container text-center">
                        <p class="text-secondary">{{ $t('getting_started.by_signing_up_you_agree_to_pillars') }} <a class="underline" target="_blank" href="/terms-of-service">{{ $t('common.terms_of_service') }}</a> {{ $t('getting_started.and') }} <a class="underline" target="_blank" href="/privacy-policy">{{ $t('common.privacy_policy') }}</a></p>
                        <a-checkbox @change="fields.unsubscribe = arguments[0].target.checked" class="text-secondary">{{ $t('getting_started.not_subscribe_text') }}</a-checkbox>
                    </div>
                </template>
                <template v-else-if="view == 'form'">
                    <h5 class="text-primary mb-40"><img class="register-popup-contents__form-back" src="/img/arrow-left.svg" alt="back" @click.stop="goBackForm()"> {{ $t('getting_started.sign_up_to_view_your_website') }}</h5>
                    <div class="d-flex justify-content-between">
                        <div class="input-group w-50 mr-20">
                            <input class="input" type="text" :placeholder="$t('getting_started.first_name_placeholder')" v-model="fields.name" @input="errors.name=''">
                            <label for="" class="subtitle text-primary">{{ $t('getting_started.first_name') }}</label>
                            <span class="caption text-alert" v-if="errors.name">{{ errors.name }}</span>
                        </div>
                        <div class="input-group w-50">
                            <input class="input" type="text" :placeholder="$t('getting_started.last_name_placeholder')" v-model="fields.last_name" @input="errors.last_name=''">
                            <label for="" class="subtitle text-primary">{{ $t('getting_started.last_name') }}</label>
                            <span class="caption text-alert" v-if="errors.name">{{ errors.last_name }}</span>
                        </div>
                    </div>
                    <div class="input-group">
                        <input class="input" type="email" :placeholder="$t('getting_started.email_address_placeholder')" v-model="fields.email" @input="errors.email=''">
                        <label for="" class="subtitle text-primary">{{ $t('getting_started.email_address') }}</label>
                        <span class="caption text-alert" v-if="errors.email">{{ errors.email }}</span>
                    </div>
                    <div class="input-group mt-auto mb-10">
                        <input class="input" type="password" :placeholder="$t('getting_started.create_password_placeholder')" v-model="fields.password" @input="errors.password=''">
                        <label for="" class="subtitle text-primary">{{ $t('getting_started.create_password') }}</label>
                        <span class="caption text-alert" v-if="errors.password">{{ errors.password }}</span>                        
                    </div>
                    <vue-recaptcha class="recaptcha-wrapper" sitekey="6LftT9UUAAAAAPTSaLyEZEZ87pE5Z21H4IIzWL1n" @verify="verify"></vue-recaptcha>
                    <a-spin :spinning="spin.pressed_register">
                        <button :disabled="fields['g-recaptcha-response']==''" class="button button-l button-primary button-block" @click.stop="signUp()">{{ $t('getting_started.sign_up') }}</button>
                    </a-spin>
                   
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        mounted() {
        },
        props: {
            template_id: {
                type: Number,
                default: null
            },
            email: {
                type: String,
                default: ''
            } 
        },
        data() {
            return {
                view: 'register_method',
                spin: {
                    pressed_register: false
                },
                fields: {
                    name: '',
                    last_name: '',
                    email: '',
                    password: '',
                    unsubscribe: false ,
                    language: i18n.locale,
                    'g-recaptcha-response': '',
                    user_refferal: '',
                },
                errors: {
                    name: '',
                    last_name: '',
                    email: '',
                    password: '',
                }
            }
        },
        methods: {
            verify(response) {
                this.fields['g-recaptcha-response'] = response;
            },
            showPasswordForm() {
                this.view = "password_form"
            },
            goBackForm() {
                this.view = "register_method";
                this.fields['g-recaptcha-response'] = '';
            },
            close() {
                console.log('close');
                this.$emit("closed");
            },
            signUp() {
                const url = `/guest/register`;
                this.spin.pressed_register = true;
                let data = this.fields;
                
                if (this.template_id) {
                    data.template_id = this.template_id;
                }

                return axios.post(url, data)
                // get data
                .then(function (response) {
                    if (typeof mixpanel === 'object') {
                        mixpanel.identify(response.data.data.user.id);

                        mixpanel.people.set({
                            "$email": response.data.data.user.email,
                            "Date account created": response.data.data.user.created_at,
                            "USER_ID": response.data.data.user.id,
                            "$first_name": response.data.data.user.name, 
                            "$last_name": response.data.data.user.last_name,
                            "$name": `${response.data.data.user.name} ${response.data.data.user.last_name}`, 
                            "Subscriber": response.data.data.user.is_subscribed,
                            "Listing published": "No"
                        });

                        console.log('mixpanel user saved');
                    }

                    if (typeof dataLayer === "object") {
                        dataLayer.push({
                            'event': 'registrationComplete'
                        });

                        console.log('GA user registered');
                    }

                    window.location.href = response.data.data.route;
                }.bind(this))
                .catch(error => {
                    this.spin.pressed_register = false;

                    let message = error.response.data.message;
                    let error_data = error.response.data.errors

                    this.$toastr.error(message);
                    this.view = 'form';
                    this.fields['g-recaptcha-response'] = '';

                    if (error_data.email) {
                        this.errors.email = error_data.email[0];
                    }

                    if (error_data.name) {
                        this.errors.name = error_data.name[0];
                    }

                    if (error_data.last_name) {
                        this.errors.last_name = error_data.last_name[0];
                    }

                    if (error_data.password) {
                        this.errors.password = error_data.password[0];
                    }
                })
            },
            emailValid() {
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(this.fields.email).toLowerCase());
            },
            addParameterToURL(_url, _key, _value) {
                var param = _key+'='+escape(_value);

                var sep = '&';
                if (_url.indexOf('?') < 0) {
                    sep = '?';
                } else {
                    var lastChar=_url.slice(-1);
                    if (lastChar == '&') sep='';
                    if (lastChar == '?') sep='';
                }
                _url += sep + param;

                return _url;
            }
        },
        mounted() {
            if ($cookies.isKey('user_refferal')) {
                this.fields.user_refferal = $cookies.get('user_refferal');
            }

            if (this.email) {
                this.fields.email = this.email;
            }
        },
        computed: {
            filledFields() {
                let required = ['name', 'last_name', 'email', 'phone'];

                let i;
                
                for (i = 0; i < required.length; i++) {
                    if (this.fields[required[i]] == "") {
                        return false;
                    }

                    if (required[i] == 'email') {
                        if (!this.emailValid()) {
                            return false;
                        }
                    }
                }

                return true;
            },
            passwordTooShort() {
                return this.fields.password.length < 8 ? true : false;
            },
            getFacebookRedirectUrl() {
                let url = "/guest/social-register-callback/facebook";

                if (this.fields.unsubscribe) {
                    url = this.addParameterToURL(url, 'unsubscribe', true);
                }

                if (this.template_id) {
                    url = this.addParameterToURL(url, 'template_id', this.template_id);
                }

                if (this.fields.user_refferal) {
                    url = this.addParameterToURL(url, 'user_refferal', this.fields.user_refferal);
                }

                this.addParameterToURL(url, 'language', this.fields.language);

                return url;
            },
            getGoogleRedirectUrl() {
                let url = "/guest/social-register-callback/google";

                
                if (this.fields.unsubscribe) {
                    url = this.addParameterToURL(url, 'unsubscribe', true);
                }

                if (this.template_id) {
                    url = this.addParameterToURL(url, 'template_id', this.template_id);
                }

                if (this.fields.user_refferal) {
                    url = this.addParameterToURL(url, 'user_refferal', this.fields.user_refferal);
                }

                return url;
            }
        }
    }
</script>
