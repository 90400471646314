<template>
  <div class="book-a-showing-container">
    <div class="book-a-showing" v-click-outside="onClickOutside">
      <div class="book-a-showing__header">
        <h5><template v-if="view != 'success'"><img v-if="view == 'form'" class="" src="/img/arrow-left.svg" alt="back" @click.stop="goBack()"> {{ $t('components.templates.book_a_showing.book_a_showing') }}</template></h5>
        <a class="button-close" @click.prevent="close()">
          <img src="/img/close-grey.svg" alt="close" class="close-icon">
        </a>
      </div>
      <template v-if="view =='calendar'">
        <div class="book-a-showing__inner">
          <div class="appointment-type">
            <p class="text-center subtitle">{{ $t('components.templates.book_a_showing.appointment_type') }}</p>
            <div class="appointment-buttons">
              <a class="appointment-button w-50" :class="{active: type == 'in_person'}" @click.stop="setType('in_person')">{{ $t('components.templates.book_a_showing.in_person') }}</a>
              <a class="appointment-button w-50" :class="{active: type == 'virtual_showing'}" @click.stop="setType('virtual_showing')">{{ $t('components.templates.book_a_showing.virtual_showing') }}</a>
            </div>
          </div>
          
          <div v-if="days.length > 3" class="appointment-date">
            <p class="text-center subtitle">{{ $t('components.templates.book_a_showing.appointment_date') }}</p>
            <div class="appointment_date__wrap">
              <div class="appointment_date__prev_day" @click.prevent="getPrevDay()">
                <img src="img/templates/components/arrow-left.svg" alt="">
              </div>

              <div class="appointment_date__day" :class="{active: selected_day == current_day}" @click.stop="setDay(current_day)">
                <p class="week_day">{{ days[current_day].week_day }}</p>
                <p class="week_day week_day--mobile">{{ days[current_day].week_day_short }}</p>
                <p class="day">{{ days[current_day].day }}</p>
                <p class="month">{{ days[current_day].month_short }}</p>
              </div>
              <div class="appointment_date__day" :class="{active: selected_day == current_day+1}" @click.stop="setDay(current_day+1)">
                <p class="week_day">{{ days[current_day+1].week_day }}</p>
                <p class="week_day week_day--mobile">{{ days[current_day+1].week_day_short }}</p>
                <p class="day">{{ days[current_day+1].day }}</p>
                <p class="month">{{ days[current_day+1].month_short }}</p>
              </div>
              <div class="appointment_date__day" :class="{active: selected_day == current_day+2}" @click.stop="setDay(current_day+2)">
                <p class="week_day">{{ days[current_day+2].week_day }}</p>
                <p class="week_day week_day--mobile">{{ days[current_day+2].week_day_short }}</p>
                <p class="day">{{ days[current_day+2].day }}</p>
                <p class="month">{{ days[current_day+2].month_short }}</p>
              </div>

              <div class="appointment_date__next_day" @click.prevent="getNextDay()">
                <img src="img/templates/components/arrow-right.svg" alt="">
              </div>
            </div>
          </div>

          <div class="appointment-time">
            <p class="text-center subtitle">{{ $t('components.templates.book_a_showing.preferred_time') }}</p>
            <div class="appointment-buttons">
              <a class="appointment-button w-33" :class="{active: preferred_time == 'morning'}" @click.stop="setTime('morning')">{{ $t('components.templates.book_a_showing.morning') }}</a>
              <a class="appointment-button w-33" :class="{active: preferred_time == 'afternoon'}" @click.stop="setTime('afternoon')">{{ $t('components.templates.book_a_showing.afternoon') }}</a>
              <a class="appointment-button w-33" :class="{active: preferred_time == 'evening'}" @click.stop="setTime('evening')">{{ $t('components.templates.book_a_showing.evening') }}</a>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="view == 'form'">
        <div class="book-a-showing__inner">
          <div class="input-row">
            <label for="">{{ $t('components.templates.book_a_showing.full_name') }}</label>
            <input type="text" :placeholder="$t('components.templates.book_a_showing.john_doe')" v-model="booking_details.full_name">
          </div>
          <div class="input-row">
            <label for="">{{ $t('components.templates.book_a_showing.email_address') }}</label>
            <input type="text" :placeholder="$t('components.templates.book_a_showing.johndoe_realestate_com')" v-model="booking_details.email">
          </div>
          <div class="input-row">
            <label for="">{{ $t('components.templates.book_a_showing.phone_number') }}</label>
            <input type="text" :placeholder="$t('components.templates.book_a_showing.phone_number_placeholder')" v-model="booking_details.phone_number">
          </div>
          <div class="input-row">
            <label for="">{{ $t('components.templates.book_a_showing.additional_information') }}</label>
            <textarea :placeholder="$t('components.templates.book_a_showing.i_would_like_to_shedule_a_showing')" v-model="booking_details.additional_info"></textarea>
          </div>

          <button class="confirm-booking" @click.stop.prevent="confirmBooking()">{{ $t('components.templates.book_a_showing.confirm_booking') }}</button>
        </div>
      </template>
      <template v-else>
        <div class="book-a-showing__inner">
          <template v-if="agent_info.agent_profile_photo">
            <div class="book-a-showing__avatar">
              <img class="" :src="agent_info.agent_profile_photo" alt="">
            </div>
          </template>
          <h2>{{ getType }} {{ $t('components.templates.book_a_showing.tour_confirmed_for') }} {{ getWeekDay }}, {{ getDayAndMonth }}!</h2>
          <p class="thank-you-text">{{ $t('components.templates.book_a_showing.thanks_for_booking') }}</p>
          <p class="text-secondary">{{ agent_info.contact_email }}</p>
          <p class="text-secondary">M: {{ agent_info.mobile_phone_number }}</p>
          <div class="book-a-showing__social">
            <a v-if="agent_info.linkedin" class="social-profile-check" target="_blank" :href="agent_info.linkedin">
              <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 28 28">
                <g>
                  <g>
                    <path fill="#999"
                      d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14zm9.894-2.405H6.72v9.533h3.173zm.209-2.948C10.082 7.712 9.413 7 8.328 7s-1.795.712-1.795 1.647c0 .915.689 1.647 1.754 1.647h.02c1.107 0 1.796-.732 1.796-1.647zm11.233 7.015c0-2.928-1.566-4.29-3.653-4.29-1.685 0-2.439.925-2.86 1.574v-1.35H11.65c.042.894 0 9.532 0 9.532h3.173v-5.323c0-.285.02-.57.104-.774.23-.569.752-1.158 1.628-1.158 1.149 0 1.608.874 1.608 2.155v5.1h3.173z" />
                  </g>
                </g>
              </svg>
            </a>
            <a v-if="agent_info.facebook" class="social-profile-check" target="_blank" :href="agent_info.facebook">
              <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 28 28">
                <g>
                  <g>
                    <path fill="#999"
                      d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14zm15.46 8.232v-7.617h2.102l.278-2.625h-2.38l.003-1.314c0-.684.065-1.05 1.048-1.05h1.315V7h-2.103c-2.526 0-3.415 1.273-3.415 3.415v1.575h-1.575v2.625h1.575v7.617z" />
                  </g>
                </g>
              </svg>
            </a>
            <a v-if="agent_info.twitter" class="social-profile-check" target="_blank" :href="agent_info.twitter">
              <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 28 28">
                <g>
                  <g>
                    <path fill="#999"
                      d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14zm13.58-2.62l.03.484-.49-.06c-1.782-.227-3.339-.998-4.66-2.293l-.647-.643-.167.475c-.352 1.058-.127 2.175.607 2.926.392.415.304.475-.372.228-.235-.08-.44-.139-.46-.11-.068.07.167.97.353 1.326.254.494.773.978 1.341 1.265l.48.227-.568.01c-.548 0-.568.01-.51.218.197.642.97 1.325 1.832 1.621l.607.208-.529.316a5.513 5.513 0 0 1-2.624.732c-.44.01-.803.05-.803.079 0 .099 1.195.652 1.89.87 2.086.642 4.563.366 6.424-.732 1.322-.78 2.644-2.333 3.261-3.836.333-.8.666-2.264.666-2.966 0-.454.03-.514.578-1.057.323-.317.626-.663.685-.762.098-.188.088-.188-.411-.02-.833.297-.95.258-.539-.187.304-.317.666-.89.666-1.058 0-.03-.147.02-.313.109-.176.098-.568.247-.862.336l-.529.168-.48-.326c-.264-.178-.636-.376-.832-.435-.5-.139-1.263-.12-1.714.04-1.224.444-1.997 1.59-1.91 2.846z" />
                  </g>
                </g>
              </svg>
            </a>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
    import moment from 'moment'
    
    export default {
        mounted() {
            this.booking_details.full_name = this.user_info.first_name ? `${this.user_info.first_name} ${this.user_info.last_name}` : "";
            this.booking_details.email = this.user_info.email;
            this.booking_details.phone_number = this.user_info.phone_number;

            this.getScheduleData();
        },
        methods: {
          getScheduleData() {
            let url = '/schedule';
            const path = window.location.pathname;

            if (path !== '/') {
              url = `${path}${url}`;
            }

            axios.get(url).then(function (response) {
                let data = response.data.data;

                this.agent_info = data.agent_info;
                this.available_week_days = data.week_days;

                this.getAvailableDays();
            }.bind(this));
          },
          confirmBooking() {
            const path = window.location.pathname;
            let url = '/schedule/book';

            if (path !== '/') {
              url = `${path}${url}`;
            }

            let booking_data = {
              full_name: this.booking_details.full_name,
              email: this.booking_details.email,
              phone_number: this.booking_details.phone_number,
              additional_info: this.booking_details.additional_info,
              preferred_time: this.preferred_time,
              type: this.getType,
              month_day: this.getMonthDayWithIndicator,
              month_name: this.getMonthName,
              week_day: this.getWeekDay
            };

            axios.post(url, booking_data).then(function (response) {
                this.view = "success";
            }.bind(this)).catch(error => {
              this.$toastr.error("Please fill in the missing details");
            })
          },
          getNextDay() {
            if ((this.days.length - 3) > this.current_day) {
              this.current_day++;
            }
          },
          getPrevDay() {
            if (this.current_day > 0) {
              this.current_day--;
            }
          },
          getAvailableDays() {
            let d = moment().locale(i18n.locale);
            let days_arr = [];
            //6 weeks
            for (var i = 0; i < 42; i++) {
              let day = d.add(1, 'days').isoWeekday();

              if (this.available_week_days[day] === true) {
                let day_to_push = {
                  month_short: d.format('MMM'),
                  month: d.format('MMMM'),
                  day: d.format('D'),
                  week_day: d.format('dddd'),
                  week_day_short: d.format('ddd'),
                }

                days_arr.push(day_to_push);
              }
            }

            this.days = days_arr;
          },
          setTime(time) {
            this.preferred_time = time;
          },
          setType(type) {
            this.type = type;
          },
          setDay(day) {
            this.selected_day = day;
          },
          close() {
            this.$emit('closed', true);
          },
          onClickOutside() {
            this.$emit("closed");
          },
          checkCalendarFields() {
            if (this.selected_day !== null && this.preferred_time != "" && this.type != "") {
              this.view = "form";
            }
          },
          goBack() {
            this.selected_day = null;
            this.view = "calendar";
          }
        },
        data() {
          return {
            view: "calendar",
            agent_info: null,
            selected_day: null,
            current_day: 0,
            preferred_time: "",
            type: "",
            available_week_days: {
              1: false,
              2: false,
              3: false,
              4: false,
              5: false,
              6: false,
              7: false,        
            },
            days: [
            ],
            booking_details: {
              full_name: "",
              email: "",
              phone_number: "",
              additional_info: ""
            }
          }
        },
        watch: {
          selected_day: function (value) {
            this.checkCalendarFields();
          },
          preferred_time: function (value) {
            this.checkCalendarFields();
          },
          type: function (value) {
            this.checkCalendarFields();
          }
        },
        props: {
          user_info: Object
        },
        computed: {
          getType() {
            return this.type == 'in_person' ? this.$t('components.templates.book_a_showing.in_person') : this.$t('components.templates.book_a_showing.virtual_showing');
          },
          getWeekDay() {
            return this.days[this.selected_day].week_day;
          },
          getMonthDay() {
            return this.days[this.selected_day].day;
          },
          getMonthName() {
            return this.days[this.selected_day].month;
          },
          getMonthDayWithIndicator() {
            if (this.days.length && this.selected_day !== null) {
              let d = this.days[this.selected_day].day;

              return (d)+(31==d||21==d||1==d?"st":22==d||2==d?"nd":23==d||3==d?"rd":"th");
            }
            return "";
          },
          getDayAndMonth() {
            if (i18n.locale == 'en') {
              return `${this.getMonthDayWithIndicator} ${this.getMonthName}`;
            } else {
              return `${this.getMonthName} ${this.getMonthDay}`;
            }
          }
        }
    }
</script>

<style lang="scss">
  .book-a-showing-container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;

    .subtitle {
      color: $text-secondary;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.4px;
      text-transform: uppercase;
      margin-bottom: 12px;
    }

    .w-50 {
      width: 50%;
    }

    .w-33 {
      width: 33.33%;
    }

    .appointment-buttons {
      display: flex;

      .appointment-button {
        color: $text-landing-primary;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        border: 1px solid #D0D6E0;
        padding-top: 13px;
        padding-bottom: 13px;
        text-align: center;
        cursor: pointer;

        &:hover {
          background: #F5F5F5;
        }

        &.active {
          background: $text-landing-primary;
          color: $white;
        }

        &:not(:first-of-type) {
          border-left: 0;
        }
      }
    }

    .book-a-showing {
      width: 100%;
      max-width: 540px;
      position: absolute;
      margin-top: 70px;
      background: $white;
      padding-bottom: 40px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 24px;

      @include media(">=540px") {
        position: relative;
        margin-left: auto;
        margin-right: auto;
        margin-top: 137px;
      }

      .book-a-showing__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .button-close {
          display: inline-block;
          cursor: pointer;

          > img {
            width: 20px;
            height: 20px;
          }
        }

        h5 {
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 28px;

          img {
            cursor: pointer;
            margin-right: 10px;
          }
        }
      }

      .book-a-showing__inner {
        margin-top: 24px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: 320px;

        .book-a-showing__social {
          text-align: center;
          margin-top: 20px;

          > a {
            margin-left: 14px;
            margin-right: 14px;
          }
        }

        .book-a-showing__avatar {
          width: 130px;
          height: 130px;
          margin: auto;
          margin-bottom: 45px;

          >img {
            display: block;
            width: 130px;
            height: 130px;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        .confirm-booking {
          display: inline-block;
          width: 100%;
          height: 56px;
          background: $text-landing-primary;
          color: $white;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          letter-spacing: 0.4px;
          border: none;
          cursor: pointer;
        }

        h2 {
          font-size: 20px;
          line-height: 28px;
          text-align: center;
        }

        .thank-you-text {
          margin-top: 20px;
          margin-bottom: 32px;
          text-align: center;
          font-size: 16px;
          line-height: 24px;
          color: $text-secondary;
          font-weight: 500;
        }

        .text-secondary {
          font-size: 16px;
          line-height: 21px;
          color: $text-secondary;
          font-weight: 500;
          text-align: center;
          margin-top: 5px;
        }

        .input-row {
          margin-bottom: 20px;

          input {
            height: 48px;
            width: 100%;
            background: #FFFFFF;
            border: 1px solid #D0D6E0;
            border-radius: 2px;
            text-transform: initial;
          }

          textarea {
            width: 100%;
            background: #FFFFFF;
            border: 1px solid #D0D6E0;
            height: 80px;
            border-radius: 2px;
            text-transform: initial;
            resize: none;
          }

          input,textarea{
            padding: 12px 16px;
            -webkit-appearance: none;

            &::placeholder {
              text-transform: initial;
              font-size: 16px;
              line-height: 24px;
              color: #AEB2BD;
            }

            &:hover {
              border: 1px solid #73707D;
            }

            &:focus {
              border: 2px solid $text-landing-primary;
              padding: 11px 15px;
            }
          }

          label {
            color: $text-primary;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
          }
        }

        .appointment-type {
          margin-top: 24px;
        }

        .appointment-date {
          margin-top: 32px;

          .appointment_date__wrap {
            display: flex;
            position: relative;

            .appointment_date__day {
              display: flex;
              flex-direction: column;
              width: 33.333%;
              text-align: center;
              border: 1px solid #D0D6E0;
              padding: 16px 11px;
              cursor: pointer;

              &:hover {
                background: #F5F5F5;
              }

              &.active {
                background: $text-landing-primary;

                .week_day {
                  color: $white;
                }

                .day {
                  color: $white;
                }

                .month {
                  color: $white;    
                }
              }

              .week_day {
                color: $text-secondary;
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
                margin-bottom: 15px;
                display: none;

                @include media(">=tablet") {
                  display: block;
                }

                &.week_day--mobile {
                  display: block;
                  
                  @include media(">=tablet") {
                    display: none;
                  }
                }
              }

              .day {
                font-weight: 400;
                font-size: 36px;
                line-height: 36px;
                color: $text-landing-primary;
                margin-bottom: 17px;
              }

              .month {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: $text-secondary;
              }

              &:nth-child(3) {
                border-left: 0;
                border-right: 0;
              }
            }

            .appointment_date__next_day,
            .appointment_date__prev_day {
              top: 50%;
              transform: translateY(-50%);
              position: absolute;
              cursor: pointer;
            }

            .appointment_date__next_day {
              right: -20px;
            }

            .appointment_date__prev_day {
              left: -20px;
            }
          }
        }

        .appointment-time {
          margin-top: 32px;
        }
      }
    }
  }
</style>
