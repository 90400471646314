<template>
    <div v-if="is_open">
        <div class="lock-listing">
            <div class="lock-listing__popup">
                <div class="lock-listing__form">
                    <h2>{{ $t('components.templates.lock_listing.title') }}</h2>
                    <div class="input-wrap reduce-gap">
                        <input v-model="full_name" type="text" oninput="this.setAttribute('value', this.value);" value="">
                        <label for="">{{ $t('components.templates.lock_listing.full_name') }}</label>
                    </div>
                    <div class="input-wrap reduce-gap">
                        <input v-model="email" type="email" oninput="this.setAttribute('value', this.value);" value="">
                        <label for="">{{ $t('components.templates.lock_listing.email_address') }}</label>
                    </div>
                    <div class="input-wrap">
                        <input v-model="phone_number" type="text" oninput="this.setAttribute('value', this.value);" value="">
                        <label for="">{{ $t('components.templates.lock_listing.phone_number') }}</label>
                    </div>
                    <button :disabled="dataNotFilled" @click.prevent="addLead()" class="app-btn btn-black btn-block">{{ $t('components.templates.lock_listing.tour_this_home') }}</button>
                </div>
                <div class="lock-listing__image" :style="'background-image: url(' + cover + ')'">
                    <div class="lock-listing__image__text">
                        <h3>{{ hero }}</h3>
                        <p>{{ address }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        mounted() {
            if (!$cookies.isKey("unlock_listing")) {
                this.is_open = true;
            }
        },
        props: {
            address: String,
            hero: String,
            cover: String,
        },
        watch: {
            is_open() {
                document.querySelector('html').style.overflowY = this.is_open ? 'hidden' : null;
            }
        },
        data() {
            return {
                is_open: false,
                full_name: "",
                email: "",
                phone_number: "",
                type: "lock_listing",
            }
        },
        methods: {
            addLead() {
                let url = '/add-lead';
                const path = window.location.pathname;

                if (path !== '/') {
                    url = `${path}${url}`;
                }

                axios.post(url, {
                    email: this.email,
                    full_name: this.full_name,
                    phone_number: this.phone_number,
                    type: this.type,
                }).then(function(response) {
                    $cookies.set("unlock_listing", true, -1);
                    this.is_open = false;
                }.bind(this));
            }
        },
        computed: {
            dataNotFilled() {
                if (!this.emailValid) {
                    return true;
                }

                if (!this.phone_number) {
                    return true;
                }

                if (!this.full_name) {
                    return true;
                }
            },
            emailValid() {
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(this.email).toLowerCase());
            },
        }
    }
</script>

<style lang="scss">
    .lock-listing {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 1000;
        display: flex;
        overflow: scroll;

        a, p, span, h2, h3 {
            font-family: 'Poppins', sans-serif;
        }

        .lock-listing__popup {
            padding-left: 16px;
            padding-right: 16px;
            width: 804px;
            max-width: 100%;
            display: flex;
            flex-direction: column-reverse;
            position: relative;
            margin-left: auto;
            margin-right: auto;
            align-self: center;

            @include media('>=tablet') {
                display: flex;
                flex-direction: row;
            }

            .lock-listing__image {
                min-height: 142px;
                position: relative;
                width: 100%;
                background: #fff;
                position: relative;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;

                &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    background: rgba(0, 0, 0, 0.5);
                }

                @include media('>=tablet') {
                    height: auto;
                    max-width: 366px;
                }

                .lock-listing__image__text {
                    z-index: 1;
                    position: relative;

                    @include media('>=tablet') {
                        position: absolute;
                        bottom: 26px;
                        left: 26px;
                    }
                    
                    h3 {
                        color: #fff;
                        font-size: 30px;
                        font-weight: 400;
                        margin-top: 28px;
                        margin-left: 20px;
                        
                        @include media('>=tablet') {
                            margin-bottom: 10px;
                            font-size: 36px;
                            margin-top: 0;
                            margin-left: 0;
                        }
                    }

                    p {
                        color: #fff;
                        font-size: 16px;
                        font-weight: 400;
                        display: none;

                        @include media('>=tablet') {
                            display: block;
                        }
                    }
                }
            }

            .lock-listing__form {
                background: white;
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 24px;
                padding-bottom: 24px;

                @include media('>=tablet') {
                    padding-left: 40px;
                    padding-right: 46px;
                    padding-top: 32px;
                    padding-bottom: 28px;
                }

                h2 {
                    font-size: 36px;
                    margin-bottom: 28px;
                    font-weight: 600;
                    line-height: 1.5;
                }

                button {
                    font-weight: 600;
                }
            }
        }
    }
</style>