import '@babel/polyfill';

require('./bootstrap');

import VueRecaptcha from 'vue-recaptcha';
import VueInternationalization from 'vue-i18n';
import VueToastr2 from 'vue-toastr-2';
import { farewellDirectivePlugin } from './directives/vue-farewell';
import VueCookies from 'vue-cookies';

window.Vue = require('vue');
window.Locale = require('./vue-i18n-locales.generated');
window.i18n = new VueInternationalization({
    messages: Locale,
});
window.toastr = require('toastr');

Vue.use(VueToastr2, {
    showMethod: 'slideDown',
    hideMethod: 'slideUp',
    closeButton: false,
    debug: false,
    newestOnTop: false,
    progressBar: false,
    positionClass: 'toast-bottom-center',
    preventDuplicates: false,
    showDuration: 300,
    hideDuration: 300,
    timeOut: 5000,
});

Vue.use(i18n, VueCookies);

const intent_options = {
    start_delay: 10000,
    cookieExpire: 3
}
Vue.use(farewellDirectivePlugin, intent_options);

Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        window.event = function (event) {
            if (!(el == event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', window.event)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', window.event)
    },
});

Vue.component('vue-recaptcha', VueRecaptcha);
Vue.component('book-a-showing', require('./components/templates/BookAShowing.vue').default);
Vue.component('register-popup', require('./components/before-register/RegisterPopup.vue').default);
Vue.component('exit-intent', require('./components/templates/ExitIntent.vue').default);
Vue.component('lock-listing', require('./components/templates/LockListing.vue').default);